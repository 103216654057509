import { Controller } from '@hotwired/stimulus'

// HEXIS FRONTEND
import $console from '@hexis-hr/utils/console'

/* stimulusFetch: 'lazy' */
export default class extends Controller<HTMLElement> {
  static values = {
    rootElement: String
  }

  rootEl = document.querySelector(this.rootElementValue)
  sectionTitles = []
  subContent = new Map()
  navItems = new Map()

  // CONSTANTS FOR SELECTORS
  SELECTOR_ROOT_LEVEL_HEADINGS = '.section-head h2:not(.collapse-content .section-head h2), .collapse-title h5, .news-teaser-title h3'
  SELECTOR_SUB_LEVEL_HEADINGS = '.prose h2, .prose h3, .prose h4, .prose h5, .prose h6'

  /* --------------------------------------------------------------------------
    CONTROLLER LIFECYCLE HOOKS
    Try to keep this short and clean it should just "tell a story".
    Most of the code should be written in meaningfully named methods below.
  -------------------------------------------------------------------------- */

  // (1) Once, when the controller is first instantiated
  initialize() { }

  // (2) Anytime the controller is connected to the DOM
  connect() {
    this.buildPageOutline()
    this.highlightActiveSection()
  }

  // (3) Anytime the controller is disconnected from the DOM
  disconnect() {
    this.subContent.clear()
    this.navItems.clear()
  }


  /* --------------------------------------------------------------------------
    GET ALL HEADINGS FROM MAIN ELEMENT
  -------------------------------------------------------------------------- */
  buildPageOutline() {

    // Remove the loading indicator
    this.element.innerHTML = ''

    // Get all section titles from the main content element
    this.rootEl.querySelectorAll(this.SELECTOR_ROOT_LEVEL_HEADINGS)
      .forEach((sectionTitle) => {
        if (sectionTitle.textContent.trim().length > 0) {
          this.sectionTitles.push(sectionTitle)

          // Define subtitles/subcontent for each section title (if there is any)
          const subtitles = sectionTitle.closest('subgrid-container')
            ? sectionTitle.closest('subgrid-container').querySelectorAll(this.SELECTOR_SUB_LEVEL_HEADINGS)
            : [];
          this.subContent.set(sectionTitle, Array.from(subtitles))
        }
      })

    // Create a list of links for each section title
    this.sectionTitles.forEach((sectionTitle, index) => {
      if (!sectionTitle.id) {
        sectionTitle.id = 'heading-' + index
      }
      const link = document.createElement('a')
      link.href = '#' + sectionTitle.id;
      link.textContent = sectionTitle.textContent;
      const listItem = document.createElement('li')

      // Add classes to list item for section title
      listItem.classList.add('page-outline-section-title')

      this.navItems.set(sectionTitle.id, listItem)

      listItem.appendChild(link)
      this.element.appendChild(listItem)

      // Check if this section title has subtitles and create a nested list
      const subtitles = this.subContent.get(sectionTitle) || []
      if (subtitles.length > 0) {
        const subList = document.createElement('ul')
        subList.classList.add('sub-outline')

        subtitles.forEach((subtitle, subIndex) => {
          if (!subtitle.id) {
            subtitle.id = `subtitle-${index}-${subIndex}`
          }

          // Create link for subtitle
          const subLink = document.createElement('a')
          subLink.href = `#${subtitle.id}`
          subLink.textContent = subtitle.textContent

          // Create list item for subtitle if it has content
          if (subtitle.textContent.trim().length > 0) {
            const subListItem = document.createElement('li')
            subListItem.classList.add('sub-outline-item')

            this.navItems.set(subtitle.id, subListItem)

            subListItem.appendChild(subLink)
            subList.appendChild(subListItem)
          }

          // When accoridon is closed, click on a link should open it
          subLink.addEventListener('click', (event) => {
            event.preventDefault()
            const accordion = subtitle.closest('.collapse')
            if (accordion) {
              const accordionInput = accordion.querySelector('input')
              if (accordionInput) {
                accordionInput.checked = true
              }
            }
            subtitle.scrollIntoView({ behavior: 'smooth' })
          })
        })

        listItem.appendChild(subList)

      }
    })
  }


  /* --------------------------------------------------------------------------
    HIGHLIGHT ACTIVE SECTION IN PAGE OUTLINE USING SCROLL SPY
  -------------------------------------------------------------------------- */
  highlightActiveSection() {

    let currentActive = null

    // Create an IntersectionObserver to track when each section title enters the viewport
    const observer = new IntersectionObserver(
      (entries) => {
        let foundActive = false
        entries.forEach((entry) => {
          const anchor = this.navItems.get(entry.target.id)?.querySelector('a')

          if (anchor) {
            if (entry.isIntersecting) {
              if (currentActive) {
                currentActive.classList.remove('active')
              }

              // Add active class to the list item
              if (anchor) {
                anchor.classList.add('active')
                currentActive = anchor
              }
              foundActive = true
            }
          }
        });
        if (!foundActive && currentActive) {
          currentActive.classList.add('active')
        }
      },
      { root: null,
        rootMargin: '0px 0px -90% 0px',
        threshold: 0,
      }
    );

    this.sectionTitles?.forEach((title) => observer.observe(title))
    this.subContent.forEach((subtitles) => subtitles.forEach((subtitle) => observer.observe(subtitle)))
  }

}
